<template>
  <teleport to="body">
    <modal :show="show">
      <template #header>
        <h3>Nahrávám obrázky</h3>
      </template>
      <template #body>
        <p>Nahrávání může chvilku trvat, mějte prosím strpení.</p>
        <div uk-spinner="ratio: 3"></div>
        <p>Obrázek č: {{imgCount}}</p>
      </template>

      <template #footer>
        <br>
      </template>

    </modal>
  </teleport>

</template>

<script>

import Modal from "@/components/general/modal";

export default {
  name: "image-upload-spinner",
  components: {
    Modal,
  },
  props: {
    show: false,
    imgCount: 0,
  }
}
</script>

<style scoped>

</style>