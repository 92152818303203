<template>
  <teleport to="body">
    <modal :show="show">
      <template #header>
        <h3 v-if="success">Úspěšně uloženo</h3>
        <h3 v-if="!success">Nastala nějaká chyba</h3>
      </template>
      <template #body>
        <p>{{message}}</p>
      </template>

      <template #footer>
        <div class="uk-flex uk-flex-between">
          <button class="button small uk-margin-large-top red" @click="$emit('close')">Zavřít</button>
          <button v-if="success" @click="$emit('activate')" class="button small uk-margin-large-top">Aktivovat</button>
        </div>

      </template>

    </modal>
  </teleport>

</template>

<script>

import Modal from "@/components/general/modal";

export default {
  name: "successfulySaved",
  emits: ['close', 'activate'],
  components: {
    Modal,
  },
  props: {
    show: false,
    success: Boolean,
    message: String,
  }
}
</script>

<style scoped lang="less">
.button.red{
  border: 1px solid #AC2F2F;
  background: #AC2F2F;
  &:hover {
     background: #fff;
     color: #AC2F2F;
   }
}
</style>