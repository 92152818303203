<template>
  <section class="multistep">
    <div class="container">
      <div class="steps-count">
        <div class="steps">
          <a @click="moveToStep('firstStep', true )" class="done">1</a>
          <a @click="moveToStep('secondStep', true )" class="done">2</a>
          <a @click="moveToStep('thirdStep', true )" class="done">3</a>
          <a class="active uk-disabled">4</a>
        </div>
      </div>
      <div class="content">
        <h1>KROK č. 4 - KONTAKTY</h1>
        <div class="user-settings">
          <form class="form-label form-js-label" @submit.prevent="submit">
            <div class="row">
              <fieldset :class="{error: validated && !user.firstname, succes: validated && user.firstname}">
                <input id="firstname" type="text" name="firstname" v-model="user.firstname" required>
                <label for="firstname" :class="user.firstname ? 'Yes' : 'No'">Jméno</label>
              </fieldset>
            </div>
            <div class="row">
              <fieldset :class="{error: validated && !user.secondname, succes: validated && user.secondname}">
                <input id="secondname" type="text" name="secondname" v-model="user.secondname" required>
                <label for="secondname" :class="user.secondname ? 'Yes' : 'No'">Příjmení</label>
              </fieldset>
            </div>
            <div class="row">
              <fieldset :class="{error: validated && !user.selected <= 0, succes: validated && user.selected > 0}">
                <select class="uk-select" v-model="user.selected" id="my-select">
                  <option v-for="option in options" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
                <label for="my-select" :class="user.selected ? 'Yes' : 'No'"> Způsob kontaktu </label>
              </fieldset>
            </div>
            <div class="row">
              <fieldset :class="{error: validated && !user.street, succes: validated && user.street}">
                <input id="street" type="text" name="street" v-model="user.street" required>
                <label for="street" :class="user.street  ? 'Yes' : 'No'">Ulice</label>
              </fieldset>
            </div>
            <div class="row">
              <fieldset :class="{error: validated && !user.streetnumber, succes: validated && user.streetnumber}">
                <input id="streetnumber" type="text" name="streetnumber" v-model="user.streetnumber" required>
                <label for="streetnumber" :class="user.streetnumber ? 'Yes' : 'No'">Číslo popisné</label>
              </fieldset>
            </div>
            <div class="row">
              <fieldset :class="{error: validated && !user.city, succes: validated && user.city}">
                <input id="city" type="text" name="city" v-model="user.city" required>
                <label for="city" :class="user.city ? 'Yes' : 'No'">Obec</label>
              </fieldset>
            </div>
            <div class="row">
              <fieldset :class="{error: validated && !user.psc, succes: validated && user.psc}">
                <input id="psc" type="text" name="psc" v-model="user.psc" required>
                <label for="psc" :class="user.psc ? 'Yes' : 'No'">PSČ</label>
              </fieldset>
            </div>
            <div class="row">
              <fieldset :class="{error: validated && !user.email, succes: validated && user.email}">
                <input id="email" type="email" name="email" v-model="user.email" disabled>
                <label for="email" :class="user.email ? 'Yes' : 'No'">Email</label>
              </fieldset>
            </div>
            <div class="row">
              <div class="row-check" :class="{error: !termsConditions && validated, succes: termsConditions}">
                <input type="checkbox" value="check" v-model="termsConditions" id="check">
                <label for="check"><span class="cube"></span>
                  <span class="text">Souhlasím se <a href="https://www.realitni-samoobsluha.cz/obchodni-podminky/">všeobecnými obchodními podmínkami</a>
                                    a seznámil jsem se se <a href="https://www.realitni-samoobsluha.cz/informacni-dolozka-dle-gdpr/">zásadami zpracování osobních údajů</a>.
                </span>
                </label>
              </div>
            </div>
          </form>
        </div>
        <h1>Ověření telefonu</h1>
        <form class="form-label form-js-label">
          <div class="phone" v-if="!user.verified">
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx">
                  <select class="uk-select" v-model="step.tel1" :class="{error: validated && !step.tel1, succes: validated && step.tel1}">
                    <option v-for="option in optionsTel" :key="option.value" :value="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset :class="{error: validated && !step.tel2, succes: validated && step.tel2}">
                    <input type="number" name="phone-2" id="phone-2" v-model="step.tel2">
                    <label for="phone-2" :class="step.tel2 > 0 ? 'yes' : 'no'">*telefon</label>
                  </fieldset>
                </div>
                <div class="uk-width-1-3 fx">
                  <a @click="sendPhone" class="button white small">Poslat kod</a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-2-3 fx">
                  <fieldset :class="{error: validated && !step.kod, succes: validated && step.kod}">
                    <input type="text" name="sms" id="sms" v-model="step.kod">
                    <label for="sms" :class="step.kod > 0 ? 'yes' : 'no'">*kod sms</label>
                  </fieldset>
                </div>
                <div class="uk-width-1-3 fx">
                  <a @click="sendCode" class="button small">Ověřit</a>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p>Váš telefon je ověřen s číslem: {{user.phone}}</p>
          </div>
          <div class="row end">
            <a @click="moveToStep('thirdStep', true)" class="button white small">Zpět</a>
            <a @click="done()" class="button small">Uložit na server</a>
          </div>
        </form>
      </div>
    </div>
  </section>
  <p v-show="showError" id="error" :class="{notification: showError, notificationSucces: showSucces}">{{ msg }}</p>
  <ImageUploadSpinner :show="spinner" :imgCount="spinnerMsg"></ImageUploadSpinner>
  <successfulySaved :show="showSaved" :success="savedSuccess" :message="savedMessage"  @close="showSaved = false" @activate="activate()"></successfulySaved>
  <actiate-modal :show="showActivateError" :errors="activationErrors"  @close="showActivateError = false"></actiate-modal>
  <modal :show="activationInProgress">
    <template #header><h1>Probíhá aktivace</h1></template>
    <template #body>
      <div class="uk-flex uk-flex-center">
        <div uk-spinner="ratio: 3"></div>
      </div>
    </template>
    <template #footer><br></template>
  </modal>
</template>

<script>
import {mapGetters} from "vuex";
import Delayer from "../../../libs/delayer";
import ImageUploadSpinner from "@/components/image-upload-spinner";
import successfulySaved from "@/components/successfulySaved";
import actiateModal from "@/components/actiateModal";
import modal from "@/components/general/modal";

export default {
  name: "fourthStep",
  computed: {
    ...mapGetters([
      'getId',
      'getDetails',
      'getEmail',
      'getImages'
    ])
  },
  components:{
    ImageUploadSpinner,
    successfulySaved,
    actiateModal,
    modal

  },
  data() {
    return {
      activationInProgress: false,
      showActivateError: false,
      spinner: false,
      spinnerMsg: '',
      showSaved: false,
      savedSuccess: true,
      savedMessage: '',
      token: null,
      id: null,
      step: {
        tel1: "",
        tel2: "",
        kod: "",
      },
      optionsTel: [
        {text: '+420', value: '420'},
        {text: '+421', value: '421'},
      ],
      layout: '',
      add: null,
      preiousStep: {
        typ: 0
      },
      file: null,
      previewImage: null,
      msg: "",
      showError: false,
      showSucces: false,
      termsConditions: false,
      validated: false,
      activationErrors: [],
      user: {
        email: null,
        firstname: null,
        secondname: null,
        selected: "",
        phone: null,
        street: null,
        streetnumber: null,
        city: null,
        psc: null,
        verified: false
      },
      options: [
        { text: 'Chci zájemce kontaktovat sám', value: '1' },
        { text: 'Chci aby zájemci kontaktovali mě', value: '2' },
      ],
    }
  },
  created() {
    this.id = this.$route.params.id;
    let add = this.$store.getters.advertById(this.id);
    if( typeof add == 'undefined' ) {
      this.$router.push({ path: '/' });
      return;
    }
    if( add.activated ){
      this.$router.push({name: 'viewonly', params: {id: this.id}})
    }
    this.add = add;
    this.step = add.steps["4"];

    let user = this.getDetails
    this.user.email = this.getEmail;
    this.user.firstname = user.jmeno;
    this.user.secondname = user.prijmeni;
    this.user.selected = user.kontakt;
    this.user.phone = user.telefon;
    this.user.street = user.ulice;
    this.user.streetnumber = user.cp;
    this.user.city = user.obec;
    this.user.psc = user.psc;
    this.user.verified = user.verified;
    this.termsConditions = user.verified;
  },
  watch:{
    showError: function(){
      Delayer.delay(4000).then(()=> {
        this.showError = false;
        this.showSucces = false;
        this.msg = '';
      });
    }
  },

  methods: {
    async sendPhone() {
      this.msg = null;
      this.showError = false;
      this.showSucces = false;

      if( !this.validate() ){
        Delayer.delay(600).then(()=> {
          document.querySelector(".error").scrollIntoView( { behavior: 'smooth'});
        });
        return;
      }

      if( !this.step.tel1 || !this.step.tel2 ){
        this.showError = true;
        this.msg = 'Vyberte předvolbu a zadejte telefonní číslo';
        return;
      }
      await this.updateCustomerDetails();
      // console.log('afterUpdate')
      await this.$store.dispatch('getToken').then((token) => this.token = token);
      let tel = this.step.tel1 + this.step.tel2;
      let phone = {
        token: this.token,
        user: {
          id: this.getId,
          telefon: tel,
        }
      };

      await this.axios.post("overovacikod/", phone)
        .then((response) => {
          if (response.data.status == "OK") {
            this.msg = response.data.info.name;
            this.showError = true;
            this.showSucces = true;
          } else {
            this.msg = response.data.info.name;
            this.showError = true;
            this.showSucces = false;
          }
          // console.log(response);
          //response => this.phone = response.data);
        }).catch((error) => {
        this.msg = "Jste offlne, prosím, připojte se k internetu a akci opakujte."
      });
    },
    async sendCode() {
      if( !this.step.kod ){
        this.validated = true;
        await Delayer.delay(600).then(()=> {
          document.querySelector(".error").scrollIntoView( { behavior: 'smooth'});
        });
        return;
      }

      await this.$store.dispatch('getToken').then((token) => this.token = token);
      let phone = {
        token: this.token,
        user: {
          kod: this.step.kod,
        }
      };

      this.axios.post("/overeni/", phone)
        .then((response) => {
          if (response.data.status == "OK") {
            this.msg = response.data.info.name;
            this.showError = true;
            this.showSucces = true;
            this.$store.dispatch('setVerified')
            this.user.verified = true;
          } else {
            this.msg = response.data.info.name;
            this.showError = true;
          }
        });
    },
    save() {
      let advert = {
        id: this.id,
        step: this.step
      };
      this.$store.dispatch('updateStepFour', advert);

      return true;
    },
    moveToStep(stepName, notValidate = false) {
      if (this.save() || notValidate) {
        this.$router.push({name: stepName, params: {id: this.id}});
      }
    },
    stepHasValues(step) {
      if( !this.add ){
        return false;
      }
      return Object.keys(this.add.steps[step]).length > 0;
    },
    async done() {
      let add = this.$store.getters.advertById(this.id);
      if( !this.user.verified){
        this.showError = true;
        this.msg = 'Nejprve potvrďte svůj telefon.'
      }
      if( add.activated === true){
        this.showError = true;
        this.msg = 'Zakázka již byla aktivována';
        return;
      }
      if (!this.validate()) {
        Delayer.delay(600).then(()=> {
          document.querySelector(".error").scrollIntoView( { behavior: 'smooth'});
        });
        // console.log('not validated');
        return;
      }
      await this.$store.dispatch('getToken').then((token) => this.token = token);
      await this.save();
      await this.updateCustomerDetails();

      await this.$store.dispatch('getToken').then((token) => this.token = token);
      let zakazakaRequest = {
        "id": add.remoteId ? add.remoteId : 0,
        "token": this.token,
        "zakazka": this.createZakazka(add),
      };
      // console.log(zakazakaRequest);
      let request = JSON.stringify(zakazakaRequest);
      this.axios.post('zakazka/', request)
        .then(async (response) => {
          if (response.data.status == "OK") {
            this.msg = response.data.info.name;
            let remoteId = response.data.info.ID;
            await this.$store.dispatch('setRemoteId', {id: add.id, remoteId: remoteId})
            await this.sendImages(() => {
              this.showSaved = true;
              this.savedSuccess = true;
              this.savedMessage = 'Zakázka byla úšpěšně uložena na server. Můžete pokračovat její aktivací.'

            });
          } else {
            this.showSaved = true;
            this.savedSuccess = false;
            this.savedMessage = response.data.info.name
          }
        });
    },
    async sendImages(after) {
      await this.$store.dispatch('getToken').then((token) => this.token = token);
      let add = this.$store.getters.advertById(this.id);
      let images = await this.getImages(add.id);
      this.spinner = true;
      for (const position in images) {
        this.spinnerMsg = position;
        let img = images[position];
        let base = img.base ? img.base.replace('data:image/jpeg;base64,', '') : null;
        let imagRequest = {
          "poradi": position,
          "id": add.remoteId,
          "token": this.token,
          "foto": {
            "base64": base
          }
        };
        await this.updateImage(imagRequest).then(async response => {
          // console.log(imagRequest);
          // console.log(response);
          if (response.data.status == "OK") {
            let path = response.data.info.path;
            if( path ){
              imagRequest.path = response.data.info.path;
              await this.$store.dispatch("setPathToImage", imagRequest);
            }
          }
        });
      }

      this.spinner = false;

      after();
    },

    async updateImage(imagRequest){
      return await this.axios.post('foto/', imagRequest )
    },

    async activate() {
      this.showSaved = false;
      if (!this.validate()) {
        return;
      }
      let add = this.$store.getters.advertById(this.id);
      if( add.activated === true){
        this.showError = true;
        this.msg = 'Zakázka již byla aktivována';
        return;
      }
      this.activationInProgress = true;
      await this.$store.dispatch('getToken').then((token) => this.token = token);
      let zakazka = {
        token: this.token,
        zakazka: {
          id: add.remoteId,
          ip: await this.getIpAddress()
        }
      };

      this.axios.post('aktivace/', zakazka).then(response => {
        // console.log(response);
        if (response.data.status == "OK") {
          this.msg = response.data.info.name;
          this.showSucces = true;
          this.showError = true;
          this.$store.dispatch("setActivated", add.id);
          this.$router.push({name: 'done', params: {id: this.id}});

        } else {
          const errors = response.data.info.err;
          errors.map((error, index) =>{
            if(error.includes('id_katastr')){
              errors.splice(index, 1);
            }
            if(error.includes('id_mesto')){
              errors.splice(index, 1, error.replace('hodnota id_mesto', 'Neplatný název obce'));
            }
          });
          this.activationErrors = errors;
          this.showActivateError = true;
        }
        this.activationInProgress = false;
      })
    },
    async getIpAddress(){
      const address = fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(({ ip }) => {
          return ip;
        });

      const printAddress = async () => {
       return await address;
      }

      return printAddress();
    },
    validate() {
      let  valid = true;
      if (!this.termsConditions)
         valid = false;

      if( !this.user.firstname || !this.user.secondname || !this.user.street || !this.user.streetnumber || !this.user.city || !this.user.psc ){
        valid = false;
      }

      this.validated = true;
      return  valid;
    },
    createZakazka(add) {
      let zakazka = {
        zadanytyp: add.steps[1].zadanytyp,
        druh: add.steps[1].druh,
        nazev: add.steps[1].nazev,
        loginid: this.getId,
        vlastnictvi: add.steps[1].vlastnictvi,
        popis_cz: add.steps[1].popis_cz,
        zadal: add.address.place
      }
      if (add.steps[1].zadanytyp == 6) {
        zakazka.pocet_pokoju = add.steps[1].pocet_pokoju;
        zakazka.uzit_plocha = add.steps[2].uzit_plocha;
        zakazka.plochapozem = add.steps[2].plochapozem;
        zakazka.stav = add.steps[2].stav;
        zakazka.postavenoz = add.steps[2].postavenoz;
        zakazka.poloha = add.steps[2].poloha;
        zakazka.vytapeni = add.steps[2].vytapeni;

        zakazka.plocha_sklep = add.steps[2].plocha_sklep;
        zakazka.plocha_lodzie = add.steps[2].plocha_lodzie;
        zakazka.pocet_garaz = add.steps[2].pocet_garaz;
        zakazka.bazen = add.steps[2].bazen;
        zakazka.plocha_balkon = add.steps[2].plocha_balkon;
        zakazka.plocha_terasa = add.steps[2].plocha_terasa;
        zakazka.park_pocet = add.steps[2].park_pocet;
      }

      if (add.steps[1].zadanytyp == 4) {
        zakazka.cislo_jednotky = add.steps[2].cislo_jednotky;
        zakazka.pater = add.steps[2].pater;
        zakazka.patro = add.steps[2].patro;
        zakazka.uzit_plocha = add.steps[2].uzit_plocha;
        zakazka.stav = add.steps[2].stav;
        zakazka.postavenoz = add.steps[2].postavenoz;
        zakazka.vytah = add.steps[2].vytah;
        zakazka.vytapeni = add.steps[2].vytapeni;
        zakazka.plocha_sklep = add.steps[2].plocha_sklep;
        zakazka.plocha_lodzie = add.steps[2].plocha_lodzie;
        zakazka.pocet_garaz = add.steps[2].pocet_garaz;
        zakazka.plocha_balkon = add.steps[2].plocha_balkon;
        zakazka.plocha_terasa = add.steps[2].plocha_terasa;
        zakazka.park_pocet = add.steps[2].park_pocet;
      }
      if (add.steps[1].zadanytyp == 3) {
        zakazka.id_parcela = add.steps[2].id_parcela;
        zakazka.celkova_plocha = add.steps[2].celkova_plocha;
        zakazka.inzsite = add.steps[2].inzsite;
        zakazka.odpad = add.steps[2].odpad;
      }

      if (add.steps[1].zadanytyp == 2) {
        zakazka.uzit_plocha = add.steps[2].uzit_plocha;
        zakazka.plochapozem = add.steps[2].plochapozem;
        zakazka.stav = add.steps[2].stav;
        zakazka.postavenoz = add.steps[2].postavenoz;
        zakazka.poloha = add.steps[2].poloha;
        zakazka.vytapeni = add.steps[2].vytapeni;
        zakazka.plocha_terasa = add.steps[2].plocha_terasa;
        zakazka.pocet_garaz = add.steps[2].pocet_garaz;
        zakazka.park_pocet = add.steps[2].park_pocet;
      }

      if (add.steps[1].zadanytyp == 11) {
        zakazka.uzit_plocha = add.steps[2].uzit_plocha;
        zakazka.plochapozem = add.steps[2].plochapozem;
        zakazka.stav = add.steps[2].stav;
        zakazka.postavenoz = add.steps[2].postavenoz;
      }

      //faktické
      zakazka.fakt = add.steps[2].vady == 1 ? 2 : 1
      zakazka.fakt03 = add.steps[2].fakt03;
      zakazka.fakt01 = add.steps[2].fakt01;
      zakazka.fakt07 = add.steps[2].fakt07;
      zakazka.fakt02 = add.steps[2].fakt02;
      zakazka.fakt04 = add.steps[2].fakt04;
      zakazka.fakt05 = add.steps[2].fakt05;
      zakazka.fakt09 = add.steps[2].fakt09;
      zakazka.fakt010 = add.steps[2].fakt10 ? add.steps[2].fakt10 : 0;

      //právní
      zakazka.pakt = add.steps[2].pravniVady == 1 ? 2 : 1;
      zakazka.pakt05 = add.steps[2].pakt05;
      zakazka.pakt04 = add.steps[2].pakt04;
      zakazka.pakt02 = add.steps[2].pakt02;
      zakazka.pakt08 = add.steps[2].pakt08;
      zakazka.pakt07 = add.steps[2].pakt07;
      zakazka.pakt06 = add.steps[2].pakt06;
      zakazka.pakt03 = add.steps[2].pakt03;
      zakazka.pakt01 = add.steps[2].pakt01;
      zakazka.pakt10 = add.steps[2].pakt10;
      zakazka.pakt11 = add.steps[2].pakt11 ? add.steps[2].pakt11 : 0;


      //vecnné břemeno
      zakazka.cakt01 = add.steps[2].cakt01;
      zakazka.cakt08 = add.steps[2].cakt08;
      zakazka.cakt02 = add.steps[2].cakt02;
      zakazka.cakt03 = add.steps[2].cakt03;
      zakazka.cakt06 = add.steps[2].cakt06;
      zakazka.cakt04 = add.steps[2].cakt04;
      zakazka.cakt07 = add.steps[2].cakt07;
      zakazka.cakt05 = add.steps[2].cakt05;

      zakazka.okres = add.address.okres;
      if( add.address.id_mesto ){
        zakazka.id_mesto = add.address.id_mesto;
      }
      if( add.address.id_katastr ){
        zakazka.id_katastr = add.address.id_katastr;
      }
      zakazka.cena = add.steps[2].cena;
      zakazka.cenatext = add.steps[2].cenatext;
      zakazka.mena = add.steps[2].mena;
      zakazka.e1 = add.steps[2].energ ? add.steps[2].energ : 7;

      zakazka.ulice = add.address.street;
      zakazka.adresa_cp = add.address.cp;
      zakazka.adresa_co = add.address.co;
      zakazka.adresa_ce = add.address.ce;
      zakazka.x_coord = add.address.latitude;
      zakazka.y_coord = add.address.longitude;

      return zakazka;
    },
    async updateCustomerDetails(){
      await this.$store.dispatch('getToken').then((token) => this.token = token);
      let data =  {
        "id": this.getId,
        "token": this.token,
        "user": {
            "email": this.user.email,
            "jmeno": this.user.firstname,
            "prijmeni": this.user.secondname,
            "kontakt": this.user.selected,
            "telefon": this.step.tel1 + this.step.tel2,
            "ulice": this.user.street,
            "cp": this.user.streetnumber,
            "obec": this.user.city,
            "psc": this.user.psc,
          },
        "verified": this.user.verified,
      };

      await this.$store.dispatch("setDetails", data).then( response => {
        if( response.data.status === 'OK' ) {
          this.msg = "Vaše údaje jsme úspěšně uložili";
          this.showSucces = true;
          this.showError = true;
        }else{
          this.msg = response.data.info.name;
          this.showError = true;
          this.showSucces = false;
        }

        // console.log(response);
      });
    },
  }
}
</script>

<style lang="less" scoped>
section.multistep {
  .container {
    position: relative;
    max-width: 42.8rem;
    height: 92vh;
    overflow: auto;
    margin: auto;
    background: #fff;
    box-sizing: border-box;
    padding-bottom: 4rem;

    .steps-count {
      background: #fff;
      padding: 1.8rem 0;

      .steps {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        max-width: 27rem;
        margin: auto;

        a {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 4.3rem;
          height: 4.3rem;
          font-family: 'Poppins';
          font-weight: 400;
          font-size: 2.1rem;
          line-height: 2.1rem;
          color: #03A3BD;
          background: #F2F2F2;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
          -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);

          &.active {
            background: #03A3BD;
            color: #fff;

            &:hover {
              background: #fff;
              color: #03A3BD;
            }
          }

          &:hover {
            text-decoration: none;
            background: #03A3BD;
            color: #fff;
          }

          &:before {
            content: "";
            position: absolute;
            top: 0;
            right: -2rem;
            bottom: 0;
            margin: auto;
            width: 0.9rem;
            height: 1.7rem;
            background: url('../../assets/images/ico-arrow-right.png');
            background-size: cover;
            background-repeat: no-repeat;
            z-index: 1;
          }

          &:last-of-type {
            &:before {
              content: none;
            }
          }
        }
      }
    }

    .content {
      padding: 2rem 2rem 6rem;

      h1 {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 3.5rem;
        text-align: center;
        color: #7C7C7B;
        margin-top: 0;
        margin-bottom: 1rem;
      }

      p {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.3rem;
        text-align: center;
        max-width: 33rem;
        margin: auto;
        margin-top: 0;
        margin-bottom: 4rem;
      }

      form {
        .row {
          margin-bottom: 2rem;

          h3 {
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 2.1rem;
            color: #03A3BD;
            margin-top: 2rem;
            margin-bottom: 1rem;
          }

          .uk-select {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0 2rem;
            width: 100%;
            height: 4rem;
            border: 1px solid #D6D6D6;
            -webkit-border-radius: 0.5rem;
            -moz-border-radius: 0.5rem;
            border-radius: 0.5rem;
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 2.1rem;
            color: #7C7C7B;
          }

          .row-check {
            position: relative;
            margin-top: 2rem;
            margin-bottom: 2rem;

            input {
              position: absolute;
              opacity: 0;

              &:checked + label {
                .cube {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  -webkit-box-pack: center;
                  -ms-flex-pack: center;
                  justify-content: center;
                  background: #2FAC66;
                  border-color: #2FAC66;

                  &:after {
                    content: '✔';
                    color: #fff;
                    font-size: 2rem;
                  }
                }
              }
            }

            label {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;

              .cube {
                display: block;
                width: 2rem;
                height: 2rem;
                background: #F2F2F2;
                border: 1px solid #D6D6D6;
                margin-right: 1rem;
              }

              .text {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 2.1rem;
                color: #7C7C7B;
                max-width: 90%;
              }
            }
          }

          .uk-grid {
            > div {
              .button {
                width: 100%;
              }

              &.fx {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin-top: 1rem;

                fieldset {
                  margin-top: 0;
                }
              }

              .row-row {
                position: relative;

                input {
                  position: absolute;
                  opacity: 0;

                  &:checked + label {
                    background: #03A3BD;
                    color: #fff;
                    border-color: #03A3BD;

                    span {
                      color: #fff;
                    }
                  }
                }

                label {
                  position: relative;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  border: 1px solid #D6D6D6;
                  background: #F2F2F2;
                  color: #2FAC66;
                  box-sizing: border-box;
                  padding: 1rem 1.5rem;
                  -webkit-border-radius: 0.5rem;
                  -moz-border-radius: 0.5rem;
                  border-radius: 0.5rem;

                  svg {
                    display: inline-block;
                    max-height: 3rem;
                    max-width: 4rem;
                    margin-right: 0.5rem;
                  }

                  span {
                    font-family: 'Poppins';
                    font-weight: 700;
                    font-size: 1.4rem;
                    line-height: 2.1rem;
                    color: #7C7C7B;
                  }
                }
              }
            }
          }

          fieldset {
            width: 100%;
            margin-top: 3rem;

            input {
              position: relative;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              padding: 0 2rem;
              width: 100%;
              height: 4rem;
              border: 1px solid #D6D6D6;
              -webkit-border-radius: 0.5rem;
              -moz-border-radius: 0.5rem;
              border-radius: 0.5rem;
              font-family: 'Poppins';
              font-weight: 700;
              font-size: 1.4rem;
              line-height: 2.1rem;
              color: #7C7C7B;
            }

            label {
              font-size: 14px;
              left: 0.8rem;

              &.yes {
                font-size: 12px;
                -webkit-transform: translate3d(0, -3rem, 0) !important;
                transform: translate3d(0, -3rem, 0) !important;
                border: 1px solid #b8b8b8;
                padding: 3px;
                background: #fff;
                border-radius: 3px;
              }
            }

            > span.error {
              position: absolute;
              top: 0.5rem;
              right: 1.1rem;
              font-family: 'Poppins';
              font-weight: 400;
              font-size: 1.2rem;
              line-height: 1.8rem;
              color: #AC2F2F;
              background: #fff;
              z-index: 2;
              height: 3rem;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
            }

            label {
              left: 0.8rem;
            }

            .button-right {
              text-align: right;
              max-width: 100%;
              margin-top: 2rem;
              margin-bottom: 2rem;

              .buttongrey {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 2.1rem;
                color: #7C7C7B;
                padding: 0.6rem 1.6rem;
                box-sizing: border-box;
                border: 1px solid #D6D6D6;
                background: #F2F2F2;
                -webkit-border-radius: 2rem;
                -moz-border-radius: 2rem;
                border-radius: 2rem;
                text-decoration: none;

                img {
                  display: inline-block;
                  margin-left: 1rem;
                }

                &:hover {
                  text-decoration: none;
                }
              }
            }

            &.error {
              textarea {
                border-color: #AC2F2F;
              }

              input {
                border-color: #AC2F2F;
              }

              label {
                border-color: #AC2F2F;
                color: #AC2F2F;
              }
            }

            &.succes {
              textarea {
                border-color: #04ac66;
              }

              input {
                border-color: #04ac66;
              }

              label {
                border-color: #04ac66;
                color: #04ac66;
              }
            }
          }

          &.end {
            padding: 2rem 0;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
.user-settings{
  margin-top: 4rem;
  form{
    .row{
      margin-bottom: 3rem;
      fieldset{
        max-width: 30rem;
        position: relative;
        margin: auto;
        input{
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0 2rem;
          width: 100%;
          height: 4rem;
          border: 1px solid #D6D6D6;
          -webkit-border-radius: 0.5rem;
          -moz-border-radius: 0.5rem;
          border-radius: 0.5rem;
          font-family: 'Poppins';
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 2.1rem;
          color:#7C7C7B;
          &.error{
            border-color: #AC2F2F;
            color: #AC2F2F;
          }
          &.succes{
            border-color: #04ac66;
          }
        }
        label{
          &.Yes{
            font-size: 12px;
            -webkit-transform: translate3d(0, -3rem, 0) !important;
            transform: translate3d(0, -3rem, 0) !important;
            border: 1px solid #b8b8b8;
            padding: 3px;
            background:#fff;
            border-radius: 3px;
          }
        }
        >span.error{
          position: absolute;
          top: 0.5rem;
          right: 1.1rem;
          font-family: 'Poppins';
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.8rem;
          color: #AC2F2F;
          background: #fff;
          z-index: 2;
          height: 3rem;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
        img{
          position: absolute;
          top: 0;
          right: 1.5rem;
          bottom: 0;
          margin: auto;
        }
        &.error{
          select{
            border-color: #AC2F2F;
          }
          input{
            border-color: #AC2F2F;
          }
          label{
            border-color: #AC2F2F;
            color: #AC2F2F;
          }
        }
        &.succes{
          select{
            border-color: #04ac66;
          }
          input{
            border-color: #04ac66;
          }
          label{
            border-color: #04ac66;
            color: #04ac66;
          }
        }
      }
      &.end{
        margin-top: 6.3rem;
        button{
          margin:auto;
        }
      }
      .uk-select{
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 2rem;
        width: 100%;
        max-width: 30rem;
        margin:auto;
        height: 4rem;
        border: 1px solid #D6D6D6;
        -webkit-border-radius: 0.5rem;
        -moz-border-radius: 0.5rem;
        border-radius: 0.5rem;
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 2.1rem;
        color:#7C7C7B;
      }
    }
  }
}

.row-check.error label .cube{
  border: 1px solid #AC2F2F !important;
}
</style>