<template>
  <teleport to="body">
    <modal :show="show">
      <template #header>
        <h3>Zakázku se nepodařilo aktivovat:</h3>
      </template>
      <template #body>
        <p>Prosím opravte:</p>
        <div>
          <ul>
            <li v-for="(error, index) in errors" :key="index">
              <p>{{error}}</p>
            </li>
          </ul>
        </div>
      </template>

      <template #footer>
        <button class="button small uk-margin-large-top red" @click="$emit('close')">Zavřít</button>
      </template>

    </modal>
  </teleport>

</template>

<script>

import Modal from "@/components/general/modal";

export default {
  name: "activationModal",
  components: {
    Modal,
  },
  emits: ['close'],
  props: {
    show: false,
    errors: Array,
  }
}
</script>

<style scoped lang="less">
.button.red{
  border: 1px solid #AC2F2F;
  background: #AC2F2F;
  &:hover {
     background: #fff;
     color: #AC2F2F;
   }
}
</style>